// extracted by mini-css-extract-plugin
export var ammoCaseStudyVideo = "bC_l9";
export var caseContactFormSection = "bC_k3";
export var caseSolutionRing = "bC_mb";
export var caseStudyBackground__bgColor = "bC_k5";
export var caseStudyBackground__lineColor = "bC_k0";
export var caseStudyCta__bgColor = "bC_l4";
export var caseStudyHead = "bC_kW";
export var caseStudyHead__imageWrapper = "bC_kX";
export var caseStudyProjectsSection = "bC_k4";
export var caseStudyQuote__dark = "bC_l6";
export var caseStudyQuote__light = "bC_l7";
export var caseStudySolution__ringOne = "bC_l8";
export var caseStudySolution__ringTwo = "bC_k9";
export var caseStudyTechSection = "bC_ld";
export var caseStudyVideo__ring = "bC_k2";
export var caseStudy__bgDark = "bC_kV";
export var caseStudy__bgLight = "bC_kT";